import {Image} from 'core/components'
import {Product} from 'model'
import {Stack, Text, createStyles} from 'ui/core'
import {rem} from 'ui/styles'

const useStyles = createStyles((theme) => ({
  image: {
    width: rem(150),
    height: rem(150),
    [theme.fn.largerThan('md')]: {
      width: rem(200),
      height: rem(200),
    },
  },
}))

interface SwapOfferProductProps {
  product: Product
}

const SwapOfferProduct = ({product}: SwapOfferProductProps) => {
  const {classes} = useStyles()
  return (
    <Stack spacing={4}>
      <Text align="center" lineClamp={1}>
        {product?.title}
      </Text>
      <Image
        className={classes.image}
        width={200}
        height={200}
        src={product?.image}
        alt={product.title || ''}
      />
    </Stack>
  )
}

export default SwapOfferProduct
