import {Welcome, SellCategories, RecentActivities, SwapOffers} from 'components/Home'
import {Category, Product, SwapOffer} from 'model'
import {GetStaticProps, InferGetStaticPropsType} from 'next'
import {CATEGORY_FIELDS, PRODUCT_FIELDS, getSanityClient} from '@resellam/sanity'
import {useAuth} from '@resellam/auth'
import {Container, Stack} from 'ui/core'
import {Deals} from 'components'
import {fetch, filter, order, slice} from '@aonawale/sanity-query'

const getCategories = () =>
  fetch<Category[]>(
    getSanityClient(),
    {
      constraints: [
        filter('_type', '==', 'category'),
        filter('active', '==', true),
        filter('featured', '==', true),
        order('_createdAt', 'asc'),
        slice(0, 4),
      ],
    },
    CATEGORY_FIELDS,
  )

const getProductDeals = () =>
  fetch<Product[]>(
    getSanityClient(),
    {
      constraints: [
        filter('_type', '==', 'product'),
        filter('active', '==', true),
        filter('featured', '==', true),
        filter('discount', '!=', null),
        filter('references(*[_type=="discount" && dateTime(endDate) >= dateTime(now())]._id)'),
        order('_updatedAt', 'asc'),
      ],
    },
    PRODUCT_FIELDS,
  )

const getProductBySlug = (slug: string) =>
  fetch<Product>(
    getSanityClient(),
    {
      constraints: [filter('_type', '==', 'product'), filter('slug.current', '==', slug), slice(0)],
    },
    PRODUCT_FIELDS,
  )

const getSwapOffers = async (): Promise<SwapOffer[]> => {
  const iPhone13 = await getProductBySlug('iphone-13')
  const iPhone13Pro = await getProductBySlug('iphone-13-pro')
  const iPhone12ProMax = await getProductBySlug('iphone-12-pro-max')
  const iPhone13ProMax = await getProductBySlug('iphone-13-pro-max')
  return [
    {
      id: Math.random().toString(36).slice(2, 11),
      products: [iPhone13, iPhone13Pro],
      price: 140000,
    },
    {
      id: Math.random().toString(36).slice(2, 11),
      products: [iPhone12ProMax, iPhone13ProMax],
      price: 135000,
    },
  ]
}

export const getStaticProps: GetStaticProps<{
  categories: Category[]
  products: Product[]
  swapOffers: SwapOffer[]
}> = async () => {
  const categories = await getCategories()
  const products = await getProductDeals()
  const swapOffers = await getSwapOffers()

  return {
    props: {
      categories,
      products,
      swapOffers,
    },
  }
}

const HomePage = ({
  categories,
  products,
  swapOffers,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const {user} = useAuth()
  return (
    <>
      <Welcome />
      <Container sx={{marginTop: 48}}>
        <Stack spacing={48}>
          {user && <RecentActivities />}
          <SellCategories categories={categories} />
          <Deals title="Shop Deals" products={products} />
          <SwapOffers swapOffers={swapOffers} />
        </Stack>
      </Container>
    </>
  )
}

export default HomePage
