import {
  Title,
  Text,
  Group,
  useMantineTheme,
  Container,
  Box,
  createStyles,
  MediaQuery,
} from 'ui/core'
import {ProductSearch} from 'components'
import {WELCOME_PAGE_PRODUCTS_SEARCH_ID} from 'helpers/constants'
import {theme as uiTheme} from 'core/utils'
import {Image} from 'core/components'
import {useDynamicContent} from './useDynamicContent'

const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: uiTheme.colors.secondary.waterBlue,
    padding: '0 16px 48px 16px',
    margin: '0 -16px',
  },
  dynamixText: {
    fontSize: '40px',
    lineHeight: '1.2',
    [theme.fn.largerThan('sm')]: {
      fontSize: '48px',
    },
    [theme.fn.largerThan('md')]: {
      fontSize: '68px',
    },
  },
  dynamicImage: {
    position: 'relative',
    width: '400px',
    height: '300px',
    [theme.fn.largerThan('sm')]: {
      width: '500px',
      height: '400px',
    },
  },
  leftSection: {
    [theme.fn.largerThan('xs')]: {
      flexShrink: 0,
      width: '40%',
    },
  },
  description: {
    fontSize: '18px',
    [theme.fn.largerThan('lg')]: {
      fontSize: '20px',
    },
  },
}))

const Welcome = () => {
  const {classes} = useStyles()
  const theme = useMantineTheme()
  const dynamicContent = useDynamicContent()

  return (
    <Box className={classes.root}>
      <Container>
        <Group noWrap mb="md" spacing="md" position="apart">
          <Box className={classes.leftSection}>
            <Title order={2}>Buy, Swap, Sell</Title>
            <Title order={1} mt="xs" className={classes.dynamixText}>
              <span style={{color: theme.colors.blue[5]}}>{dynamicContent.text}</span>
            </Title>
            <Text mt="xs" className={classes.description}>
              Get paid instantly for your used or new phones, laptops, and gadgets from the comfort
              of your home
              {/* New, UK Used, Naija Used Gadgets and get paid instantly from the comfort of your home */}
            </Text>
          </Box>
          <MediaQuery smallerThan="sm" styles={{display: 'none'}}>
            <Box className={classes.dynamicImage}>
              <Image
                priority
                fill
                quality={100}
                alt="welcome image"
                src={dynamicContent.imageUrl}
              />
            </Box>
          </MediaQuery>
        </Group>
        <ProductSearch
          transitionProps={{
            transition: 'slide-up',
          }}
          triggerProps={{
            variant: 'pill',
            id: WELCOME_PAGE_PRODUCTS_SEARCH_ID,
          }}
        />
      </Container>
    </Box>
  )
}

export default Welcome
