import {CategoryCard, SectionTitle, RowCarousel} from 'components'
import {Category} from 'model'
import Link from 'next/link'
import {groupCategories} from 'helpers/category'
import {useMemo} from 'react'
import analytics from 'lib/analytics'
import {Box} from 'ui/core'

const SellCategories = ({categories}: {categories: Category[]}) => {
  const groupedCategories = useMemo(() => Object.values(groupCategories(categories)), [categories])

  return (
    <Box w="100%">
      <SectionTitle
        title="Things to Sell"
        rightAction={{
          title: 'See all',
          href: '/sell',
        }}
      />
      <RowCarousel slideSize="50%" items={groupedCategories}>
        {(category) => (
          <CategoryCard
            component={Link}
            key={category.id}
            href={
              category.subCategories ? `/sell?group=${category.slug}` : `/sell/${category.slug}`
            }
            imageWidth={150}
            imageHeight={150}
            title={category.title}
            image={category.image}
            onClick={() => {
              if (category.subCategories) {
                analytics.track('view_category_group', {
                  category: 'engagement',
                  category_group_name: category.title,
                })
              } else {
                analytics.track('view_category', {
                  category: 'engagement',
                  category_name: category.title,
                })
              }
            }}
          />
        )}
      </RowCarousel>
    </Box>
  )
}

export default SellCategories
