import {CatalogueCard} from 'components'
import {Money} from 'core/components'
import {SwapOffer} from 'model'
import Link from 'next/link'
import {Box, Group, Icon, Stack, Text} from 'ui/core'
import {SwapOfferProduct} from '../SwapOfferProduct'

interface SwapOfferCardProps {
  swapOffer: SwapOffer
}

const SwapOfferCard = ({swapOffer}: SwapOfferCardProps) => (
  <CatalogueCard
    component={Link}
    href={`/sell/${swapOffer.products[0].category?.slug}/${swapOffer.products[0].brand?.slug}/${swapOffer.products[0].slug}`}
  >
    <Stack spacing={4}>
      <Group align="center" noWrap spacing={2}>
        <SwapOfferProduct product={swapOffer.products[0]} />
        <Box lh={0} sx={{flexShrink: 0}}>
          <Icon name="repeat" size={28} />
        </Box>
        <SwapOfferProduct product={swapOffer.products[1]} />
      </Group>
      <Text align="center">
        From <Money inline component="span" size="lg" weight="bold" value={swapOffer.price} />
      </Text>
    </Stack>
  </CatalogueCard>
)

export default SwapOfferCard
